import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { renderPreviewComponents } from '../components';
import { GetStaticPageSettings } from '../helper/staticPages';
import Layout from '../modules/layout';
import SEO from '../modules/seo';

const contentful = require('contentful');

const PreviewPage = ({ data: { allContentfulSiteSettings, microcopy, allContentfulPageGlobal } }) => {
  const siteSettings = GetStaticPageSettings();
  const [contentComponents, setContentComponents] = useState([]);
  const [locale, setLocale] = useState('de');
  const [seo, setSeo] = useState({});
  const localizedMicrocopy = {};
  const nodes = allContentfulPageGlobal.nodes;

  microcopy.edges
    .map((edge) => edge.node)
    .forEach((node) => {
      if (node.node_locale === siteSettings.node_locale) {
        localizedMicrocopy[node.key] = node.value;
      }
    });

  siteSettings.microcopy = localizedMicrocopy;

  const client = contentful.createClient({
    space: 'czizkt6r0elt',
    accessToken: 'AfGw4Fd6DR6996dj_lmcD6RWBwv5MYz_zblLXgrlxMs',
    host: 'preview.contentful.com',
    environment: process.env.GATSBY_CONTENTFUL_ENVIRONMENT,
    resolveLinks: true
  });

  useEffect(() => {
    const url = new URL(window.location);
    const pageId = url.searchParams.get('pageId');
    const node = nodes.find((node) => node.localizedPage?.contentful_id === pageId)
    const node_locale = node?.localizedPage?.node_locale;

    client
      .getEntry(pageId, {
        include: 5,
        locale: node_locale
      })
      .then((page) => {
        setContentComponents(page.fields.components);
        setSeo({
          title: page?.fields.seoTitle,
          description: page?.fields.seoDescription,
          metaTags: page?.fields.metaTags,
          ogImage: page?.fields.ogImage,
          structuredData: page?.fields.structuredData,
        });
        setLocale(page.sys.locale);
      })
      .catch(console.error);
  }, []);

  return (
    <Layout settings={siteSettings}>
      <SEO
        title={seo?.title}
        description={seo?.description}
        structuredData ={seo?.structuredData}
        globalCss={siteSettings?.globalCss}
        metaTags={seo?.metaTags}
        ogImage={seo?.ogImage}
      />
      {contentComponents && renderPreviewComponents(contentComponents, locale, nodes)}
    </Layout>
  );
};

export const pageQuery = graphql`
  query siteSettingsPreview {
    microcopy: allContentfulMicrocopy {
      edges {
        node {
          node_locale
          key
          value
        }
      }
    }
    allContentfulSiteSettings {
      edges {
        node {
          siteName
          siteDescription
          siteAuthor
          copyright
          node_locale
        }
      }
    }
    allContentfulPageGlobal {
      nodes {
        id
        slug
        fullSlug
        localizedPage {
          node_locale
          contentful_id
        }
      }
    }
  }
`;

export default PreviewPage;
